/* eslint-disable react-hooks/exhaustive-deps */
import { faLocationDot, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import apiClient from 'services/apiClient';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';

// Helper function to format dates as MM/DD/YYYY
const formatDateToUS = (date) => {
  const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
  return new Date(date)
    .toLocaleDateString('en-US', options)
    .replace(/\//g, '/');
};

// Function to calculate end date in MM/DD/YYYY format
const getEndDate = (days) => {
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + days);
  return formatDateToUS(endDate);
};

const encodeDetails = (details) => {
  return encodeURIComponent(JSON.stringify(details));
};

const PATH = process.env.REACT_APP_PATH;

const AppointmentModal = ({ allDetails, appointmentDetails, timezone }) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState({
    appointmentId: '',
    details: {},
    isModalOpen: false,
  });
  const [availability, setAvailability] = useState([]);
  const [endDate, setEndDate] = useState(getEndDate(15));
  const [showMore, setShowMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    const fetchAvailability = async () => {
      setLoading(true);
      try {
        const startDate = formatDateToUS(new Date()); // Today's date in MM/DD/YYYY format
        const response = await apiClient.get(
          `HomeApi/GetDoctorAvailabilitySlots?languageCode=${i18n.language}&timeZone=${timezone}&startdate=${startDate}&enddate=${endDate}&userprofileId=${allDetails?.UserProfileId}`
        );
        if (response?.data?.Data[0].Slots === availability?.Slots) {
          setShowMore(false);
          return;
        }
        setAvailability(response?.data?.Data[0]); // Adjust based on the actual API response structure
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchAvailability();
  }, [endDate, timezone]);

  const onOpenModal = (appointmentId, date, time) =>
    setOpen({
      appointmentId: appointmentId,
      details: {
        date: date,
        time: time,
        DoctorName: availability?.DoctorName,
        image: availability?.TumbImagePath,
        DoctorProfileId: '',
        specialty: availability?.Specialities,
      },
      isModalOpen: true,
    });
  const onCloseModal = () => {
    setText('');
    setOpen({ appointmentId: '', isModalOpen: false });
  };
  const formatDate = (dateString) => {
    const options = { weekday: 'short', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleSeeMore = () => {
    setEndDate((prevEndDate) => {
      const newEndDate = new Date(prevEndDate);
      newEndDate.setDate(newEndDate.getDate() + 5);
      return formatDateToUS(newEndDate);
    });
  };

  // Separate and sort slots
  const appointmentDate = formatDateToUS(
    new Date(appointmentDetails?.AppointmentDate)
  );
  const matchingSlots = [];
  const otherSlots = [];

  if (availability?.Slots) {
    JSON.parse(availability?.Slots).forEach((e) => {
      if (formatDateToUS(e?.Date) === appointmentDate) {
        matchingSlots.push(e);
      } else {
        otherSlots.push(e);
      }
    });
  }
  return (
    <>
      {!loading && (
        <div>
          <h2 className="text-[20px] font-bold px-7 py-4">
            {t('doctor.bookAppointment')}
          </h2>
          <div className="md:flex bg-[#B4E0E3] p-5 block">
            <div className="mr-6">
              {availability?.TumbImagePath ? (
                <img
                  src={`${PATH}/${availability?.TumbImagePath}`}
                  alt="Doctor Thumbnail"
                  className="w-[120px] h-[120px] rounded-full object-cover border-[1px] border-[#000]"
                />
              ) : (
                <Avatar />
              )}
            </div>
            <div>
              <h4 className="text-[20px] font-semibold mb-2">
                {availability?.DoctorName}
              </h4>
              <div className="flex gap-2 items-center">
                <p className=" text-[14px] font-medium">
                  {allDetails?.Specialities}
                </p>
                <div>
                  <span className="bg-[#fff] text-[#000] px-[15px] py-[5px] items-center rounded-[30px] inline-flex text-[14px] min-w-max">
                    {availability?.OverallRatingAverage}
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                  </span>
                </div>
                <div>
                  <span className="bg-[#fff] text-[#000] px-[15px] py-[5px] rounded-[30px] inline-block text-[14px] min-w-max">
                    {availability?.ReviewedUser} reviews
                  </span>
                </div>
              </div>
              <span className="mt-2 flex items-center">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="bg-white   p-2 w-[18px] h-[18px] block rounded-full  mr-3 text-[#0499A0]"
                />{' '}
                <p>{availability?.Address}</p>
              </span>
            </div>
          </div>
          {matchingSlots?.length > 0 || otherSlots.length > 0 ? (
            <div className="mt-3 px-8">
              <h3 className="text-[20px] font-medium mb-1">
                {' '}
                {t('doctor.appointments.available_appointments')}
              </h3>
              {t('doctor.appointments.click_to_book')}
            </div>
          ) : (
            <div className="mt-3 px-8">
              <h3 className="text-[20px] font-medium mb-1">
                {' '}
                {t('doctor.appointments.noAppointmentAvailable')}
              </h3>
            </div>
          )}
          {matchingSlots?.length > 0 &&
            matchingSlots?.map((e) => (
              <div className="py-4 px-8 bg-[#B4E0E3] mt-3">
                <p className="mb-4 text-[20px]">{formatDate(e?.Date)}</p>
                <div
                  className="grid md:grid-cols-5 grid-cols-3 gap-1"
                  key={e?.Date}
                >
                  {e?.Appointments?.map((availableSlot) => (
                    <button
                      key={availableSlot?.AppointmentId}
                      className="bg-[#0499A0] text-white p-2 rounded-[4px] mb-3 text-[12px]"
                      onClick={() =>
                        onOpenModal(
                          availableSlot?.AppointmentId,
                          e?.Date,
                          availableSlot?.AppointmentTime
                        )
                      }
                    >
                      {availableSlot?.AppointmentTime}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          {otherSlots.length > 0 && (
            <>
              {appointmentDetails && (
                <div className="mt-3 px-8">
                  <p className="text-[20px] font-semibold">
                    {t('doctor.moreAvailabilities')}
                  </p>
                </div>
              )}
              {otherSlots.map((e) => (
                <div className="py-4 px-8 bg-[#B4E0E3] mt-3">
                  <p className="mb-4 text-[20px]">{formatDate(e.Date)}</p>
                  <div
                    className="grid md:grid-cols-5 grid-cols-3 gap-1"
                    key={e.Date}
                  >
                    {e?.Appointments?.map((availableSlots) => (
                      <button
                        key={availableSlots?.AppointmentId}
                        className="bg-[#0499A0] text-white p-2 rounded-[4px]  mb-3 text-[12px]"
                        onClick={() =>
                          onOpenModal(
                            availableSlots?.AppointmentId,
                            e?.Date,
                            availableSlots?.AppointmentTime
                          )
                        }
                      >
                        {availableSlots?.AppointmentTime}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}
          {showMore && (
            <div className="mt-6 px-8">
              <button
                className="bg-[#0499A0] text-white p-2 cursor-pointer rounded-[4px] font-medium w-full mb-5"
                onClick={handleSeeMore}
              >
                {t('buttons.see_more')}
              </button>
            </div>
          )}
          <Modal
            open={open?.isModalOpen}
            onClose={onCloseModal}
            center
            classNames={{
              modal: 'small-modal-open',
            }}
          >
            <div className="p-12">
              <p className="text-[20px] mb-10">
                {t('doctor.appointments.message', {
                  doctorName: availability?.DoctorName || 'the doctor',
                })}
              </p>
              <input
                type="text"
                placeholder={t('doctor.appointments.appointment_reason')}
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              />
              <div className="flex items-center justify-between gap-12">
                <button
                  onClick={onCloseModal}
                  className=" border-[1px] border-[#0499A0] text-[#0499A0] cursor-pointer p-2 rounded-[4px] font-medium block w-full text-center"
                >
                  {t('buttons.clear')}
                </button>
                <Link
                  to={`/verify?UserProfileId=${allDetails?.UserProfileId}&AppointmentId=${open?.appointmentId}&Details=${encodeDetails(open?.details)}&timezone=${timezone}&description=${text}`}
                  className="bg-[#0499A0] text-white p-2 rounded-[4px]  block w-full font-medium text-center"
                >
                  {t('buttons.continue_booking')}
                </Link>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AppointmentModal;
