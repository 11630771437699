/* eslint-disable react-hooks/exhaustive-deps */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAnglesRight,
  faLocationDot,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useLocation } from 'react-router-dom';
import apiClient from 'services/apiClient';
import AppointmentModal from './AppointmentModal';
import DoctorMap from './DoctorMap';
import Avatar from 'components/avatar/Avatar';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';
import { Box, LinearProgress } from '@mui/material';

const PATH = process.env.REACT_APP_PATH;

const Appointment = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const previousLocation = query.get('selectedAddress');
  const specialty = query.get('specialty');
  const lat = query.get('lat');
  const lng = query.get('lng');
  const [open, setOpen] = useState({
    allDetails: {},
    appointmentDetails: {},
    isModalOpen: false,
  });
  const [selectedAddress, setSelectedAddress] = useState('');
  const [specialties, setSpecialties] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [searchSpecialty, setSearchSpecialty] = useState(specialty);
  const [gender, setGender] = useState('');
  const [spokenLanguage, setSpokenLanguage] = useState('');
  const [distance, setDistance] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({
    lat: lat,
    lng: lng,
  });
  const [address, setAddress] = useState(query.get('address'));
  const [loading, setLoading] = useState(false);
  const onOpenModal = (allDetails, appointmentDetails) => {
    setOpen({
      allDetails: allDetails,
      appointmentDetails: appointmentDetails,
      isModalOpen: true,
    });
  };
  const onCloseModal = () =>
    setOpen({ allDetails: {}, appointmentDetails: {}, isModalOpen: false });
  const [center, setCenter] = useState();
  const [availability, setAvailability] = useState([]);

  const currentYear = new Date().getFullYear(); // Get the current year
  const firstOfJanuary = new Date(currentYear, 0, 1); // Create a Date object for January 1st
  const dateString = firstOfJanuary.toString(); // Convert it to string

  const match = dateString.match(/\(([^)]+)\)$/); // Extract the timezone
  const timezone = match ? match[1] : ''; // Get the timezone or empty string

  const headersObj = {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const getSpecialties = async () => {
    try {
      const response = await apiClient.post(
        'MasterApi/GetSpeciality',
        { languageCode: 'en' },
        headersObj
      );
      setSpecialties(response?.data?.Data);
    } catch (err) {
      console.error(err);
    }
  };
  const getLanguage = async () => {
    try {
      const response = await apiClient.post(
        'MasterApi/GetLanguageMaster',
        { languageCode: i18n.language },
        headersObj
      );
      setLanguages(response?.data?.Data);
    } catch (err) {
      console.error(err);
    }
  };
  const onSearchSpecialtyChange = (value) => {
    setSearchSpecialty(value);
  };
  const fetchAvailability = async (update = false) => {
    setLoading(true);
    try {
      const response = await apiClient.get('HomeApi/GetDoctorAvailability', {
        params: {
          timeZone: timezone,
          languageCode: i18n.language,
          Speciality: searchSpecialty,
          Country: '',
          City: '',
          State: '',
          PageIndex: 1,
          PageSize: 100,
          LanguageId: '',
          SpokenLanguage: spokenLanguage,
          Rating: '',
          Gender: gender,
          Latitude: selectedLocation?.lat || '',
          Longitude: selectedLocation?.lng || '',
          Radius: distance,
        },
      });
      setCenter({
        lat: parseFloat(selectedLocation?.lat),
        lng: parseFloat(selectedLocation?.lng),
      });
      update && setSelectedAddress(address);
      setAvailability(response?.data?.Data);
    } catch (error) {
      console.error('Error fetching availability data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleReset = () => {
    setSearchSpecialty('');
    setSelectedLocation({ lat: lat, lng: lng });
    setDistance('');
    setSpokenLanguage('');
    setGender('');
    setSelectedAddress('');
    setAddress(query.get('address'));
  };
  const resetSelectedLocation = () => {
    setSelectedLocation({ lat: lat, lng: lng });
  };
  useEffect(() => {
    getSpecialties();
    getLanguage();
  }, []);
  useEffect(() => {
    if (lat && lng) {
      const place = { lat: parseFloat(lat), lng: parseFloat(lng) };
      // Center your map here using `center`
      setCenter(place);
    }
    fetchAvailability();
  }, [specialty, timezone, lat, lng]);
  return (
    <>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <div className="py-6">
        <div className="container-fluid">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-4 pl-[2.5rem] md:pr-0 pr-4">
            <div>
              <Filter
                previousLocation={previousLocation}
                queryAddress={query.get('address')}
                selectedAddress={selectedAddress}
                fetchAvailability={fetchAvailability}
                searchSpecialty={searchSpecialty}
                address={address}
                specialties={specialties}
                languages={languages}
                setSelectedLocation={setSelectedLocation}
                onSearchSpecialtyChange={onSearchSpecialtyChange}
                setAddress={setAddress}
                distance={distance}
                setDistance={setDistance}
                gender={gender}
                setGender={setGender}
                spokenLanguage={spokenLanguage}
                setSpokenLanguage={setSpokenLanguage}
                resetSelectedLocation={resetSelectedLocation}
                handleReset={handleReset}
              />
              {/* {selectedAddress && (
                <p>
                  {t('doctor.showingResultsFor', { address: selectedAddress })}
                </p>
              )} */}
              {!loading && (
                <div className="flex items-center">
                  <div>
                    <h3 className="text-[20px] text-[#0499A0] font-semibold ">
                      {t('doctor.providers', { count: availability?.length })}
                    </h3>
                  </div>
                </div>
              )}

              <div className="overflow-y-auto  md:h-[100vh] h-[400px] scrollbar-custom">
                {availability?.map((e, index) => (
                  <div
                    className="grid grid-cols-1 gap-4 mt-8 bg-[#F0F9F9] p-5 "
                    key={index}
                  >
                    <div className="md:flex block">
                      <div className="mr-6 ">
                        {e?.TumbImagePath ? (
                          <img
                            src={`${PATH}/${e?.TumbImagePath}`}
                            alt=" "
                            className="w-[120px] h-[120px] border-[1px] rounded-full object-cover border-[#000] max-w-[120px]"
                          />
                        ) : (
                          <Avatar />
                        )}
                      </div>
                      <div>
                        <h4 className="text-[20px] font-semibold mb-2">
                          {e?.DoctorName}
                        </h4>
                        <div className="flex gap-2 items-center cus_flex">
                          <p className=" text-[14px] font-medium">
                            {e?.Specialities}
                          </p>
                          <span className="bg-[#fff] text-[#000] px-[15px] py-[5px] items-center rounded-[30px] inline-flex text-[14px] min-w-max">
                            {e?.OverallRatingAverage}
                            <FontAwesomeIcon
                              icon={faStar}
                              className="text-[#FED337] w-[20px] ml-3"
                            />
                          </span>
                          <span className="bg-[#fff] text-[#000] px-[15px] py-[5px] rounded-[30px] inline-block text-[14px] min-w-max">
                            {t('doctor.reviews', { count: e?.ReviewedUser })}
                          </span>
                        </div>
                        <span className="mt-2 flex items-center">
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className="bg-white   p-2 w-[18px] h-[18px] block rounded-full  mr-3 text-[#0499A0]"
                          />{' '}
                          <p>{e?.Address} </p>
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className="p-5 bg-white rounded-[12px]">
                        <div className="grid  sm:grid-cols-4 grid-cols-3 gap-2">
                          {JSON.parse(e.AppointmentCalendar).map(
                            (appointment, index) => (
                              <div
                                key={index}
                                className={`text-center cursor-pointer p-2 rounded-[4px] text-[12px] ${appointment?.AppointmentStatus === 'Available' ? 'bg-[#0499A0] text-[#fff]' : 'bg-[#EBEAE8] text-[#BFBFBF]'}`}
                                onClick={() =>
                                  appointment?.AppointmentStatus === 'Available'
                                    ? onOpenModal(e, appointment)
                                    : undefined
                                }
                              >
                                <p>
                                  {new Date(
                                    appointment?.AppointmentDate
                                  ).toLocaleDateString('en-US', {
                                    weekday: 'long',
                                    month: 'short',
                                    day: 'numeric',
                                  })}
                                </p>
                                <p className="mt-2">
                                  {appointment?.AppointmentStatus ===
                                  'Available'
                                    ? t('doctor.appointments.available')
                                    : t('doctor.appointments.noAppointment')}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <button
                        onClick={() => onOpenModal(e, null)}
                        className="bg-white text-[16px] text-[#0499A0] py-2 px-5 flex items-center justify-between ml-auto mt-5 rounded-[8px] cursor-pointer hover:bg-[#0499A0] hover:text-[#fff]"
                      >
                        {t('doctor.appointments.viewAllAvailability')}
                        <FontAwesomeIcon
                          icon={faAnglesRight}
                          className="ml-3 w-5"
                        />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div>
              {' '}
              {center && availability && (
                <DoctorMap
                  center={center}
                  availability={availability}
                  onOpenModal={onOpenModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal open={open?.isModalOpen} onClose={onCloseModal} center>
          <AppointmentModal
            allDetails={open?.allDetails}
            appointmentDetails={open?.appointmentDetails}
            timezone={timezone}
          />
        </Modal>
      </div>
    </>
  );
};

export default Appointment;
